window.actions = window.actions || {};

window.actions = {
    ...(window.actions || {}),
    // get map helper
    getMap: (code) => maps ? (code ? maps[code] || null : maps[Object.keys(maps)[0]] || null) : null,

    map_flyto: (event, data) => {
        if (typeof mapboxgl === "undefined") {
            console.error("MapboxGL not found");
            return;
        }

        const coords = mapboxgl.LngLat.convert(data.center);

        if (!coords.lat || !coords.lng) {
            console.error('Invalid coordinates provided to the map_flyto action: ', data.center);
            return;
        }

        const map = window.actions.getMap();

        if (!map) {
            console.error('No map object found');
            return;
        }

        map.flyTo(data);
    },

    display_sidebar: (event, data) => {
        const map = window.actions.getMap(),
            sidebar = map._container.closest("main").querySelector("aside"),
            sidebarId = sidebar.id,
            name = data.sidebar; // @TODO: we need the sidebar name

        // @TODO: improve this better
        const alias = map._container.closest("[data-map-component-alias]").dataset.mapComponentAlias;

        Snowboard.request('#' + sidebarId, alias, {
            data,
            update: {
                '@_sidebar': '#' + sidebarId + ' .sidebar-wrapper',
            },
            success: function(data) {
                // @TODO: Trigger the render event here instead of specific
                // UI controls initializers
                window.initAccordion();

                // trigger dom event for tracking
                $('#' + sidebarId).trigger('ca.maps.sidebar.show', name);
            },
        });
    },

    display_popup: (event, data) => {
        if (event) {
            event.stopPropagation();
        }
        window.popup.handlePopupClick(data.popup);
    },

    open_url: (event, data) => {
        if (typeof data.target === "undefined") {
            data.target = "_self";
        }

        window.open(data.href, data.target);
    }
};

/*
 * Handle actions
 * @TODO: Handle actions via data-actions attribute on the element instead of a global function
 */
window.handleAction = (event, actions) => {
    if (event) {
        event.stopPropagation();
    }
    if (!Array.isArray(actions)) {
        console.error(`Actions is not an array`);
        return;
    }
    actions.forEach((action) => {
        if (typeof window.actions[action.action] !== 'function') {
            console.error(`Action ${action.action} does not exist on the window object`);
            return;
        }

        window.actions[action.action](event, action.data);
    });
}
